import {mapGetters} from 'vuex';
import VerifiedByPeople from "../../../../components/Common/VerifiedByPeople/index.vue";

export default {
    name: 'mobileAPP',
	i18n: require('./i18n').default,
    data() {
        return {
            os: '',
        };
    },
	components:{
		'verified-by-people': VerifiedByPeople,
	},
  	props: ['shouldHideBannerApp'],
	computed: {
		...mapGetters({
			_auth: 'cabinet/userInfo/_auth',
			isWebView: 'shared/device/isWebView',
		}),
	},
    methods: {

        getMobileOs() {
             if (navigator && navigator.userAgent.match(/Android/i)) {
                this.os = 'android';
            } else if (navigator && navigator.userAgent.match(/iPhone|iPad|iPod|Mac/i)) {
                this.os = 'ios';
            }
        },
		sendToSlonik(options) {
			const {userId, eventId} = options || {};
			this._slonik({
				event_id: eventId,
				user_id: userId,
				owner_id: userId,
				screentype: this.isDesktop ? 1 : 2,
				current_url: window.location.pathname,
				mobile_os_name: this.os
			});
		},
		downloadApp() {
			const userId = this._auth.userId;
			this.sendToSlonik({userId, eventId: 682});
		}
    },
    mounted() {
        this.getMobileOs();
		const userId = this._auth.userId;
		this.sendToSlonik({userId, eventId: 681});
    },
};
