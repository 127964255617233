export default {
	messages: {
		uk: {
			'Быстрый поиск AUTO.RIA в вашем смартфоне': 'Швидкий пошук AUTO.RIA у вашому смартфоні',
			'Загрузить с Google Play': 'Завантажити з Google Play',
			'Загрузить с App Store': 'Завантажити з App Store'
		},
		ru: {
			'Быстрый поиск AUTO.RIA в вашем смартфоне': 'Быстрый поиск AUTO.RIA в вашем смартфоне',
			'Загрузить с Google Play': 'Загрузить с Google Play',
			'Загрузить с App Store': 'Загрузить с App Store'
		}
	}
};
